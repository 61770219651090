<template>
  <div>
    <header-top-dashboard primary></header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col cols="12" class="mt-n3">
          <paginated-table
              create-new-item-label="Create New Voucher"
              :headers="headers"
              :items="items"
              :pagination-details="paginationDetails"
              @createNewItem="createNewItem"
              @fetchNextPage="fetchDataByUrl(paginationDetails.nextPageUrl)"
              @fetchPreviousPage="
              fetchDataByUrl(this.paginationDetails.previousPageUrl)
            "
              @updateItemsPerPage="updateItemsPerPage"
              @updateSelectedPage="updateSelectedPage"
          ></paginated-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";
import PaginatedTable from "@/components/Tables/PaginatedTable.vue";

export default {
  name: "vouchers",
  components: {
    HeaderTopDashboard,
    PaginatedTable,
  },
  data() {
    return {
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {text: "Owner", value: "owner"},
        {text: "Contact Number", value: "contactNumber"},
        {text: "Orders", value: "orders"},
        {text: "Status", value: "status"},
        {text: "Actions", value: "actions", sortable: false},
      ],
      items: [],
      paginationDetails: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        pageStart: 0,
        pageStop: 0,
        itemsLength: 0,
        firstPageUrl: "",
        lastPageUrl: "",
        nextPageUrl: "",
        previousPageUrl: "",
        links: [],
        path: "",
      },
    };
  },
  computed: {
    ...mapStores(useSessionStore),
  },
  methods: {
    createNewItem() {
      this.$router.push("/vouchers/create");
    },

    fetchDataByUrl(url) {
      if (!url) {
        // TODO - print alert.
        return;
      }

      this.paginationDetails.loading = true;
      this.$http
        .get(url, this.sessionStore.getHttpConfig)
        .then((result) => {
          this.items.splice(0);

          for (let i = 0; i < result.data.data.length; i++) {
            this.items.push({
              name: result.data.data[i].name,
              owner: `${result.data.data[i].owners[0].firstname} ${result.data.data[i].owners[0].lastname}`,
              contactNumber: result.data.data[i].contact_details[0]
                ? result.data.data[i].contact_details[0].number
                : "N/A",
              orders: result.data.data[i].orders.length,
              status: result.data.data[i].status,
            });
          }

          this.setPaginationDetails(result.data);
        })
        .catch((error) => {
          console.error("Error: ", error);
        })
        .finally(() => {
          this.paginationDetails.loading = false;
        });
    },

    updateItemsPerPage(newValue) {
      const searchParams = new URLSearchParams({
        size: newValue,
      });

      const url = `${this.paginationDetails.path}?${searchParams.toString()}`;

      this.fetchDataByUrl(url);
    },
  },
  mounted() {
    this.fetchDataByUrl("/vouchers");
  },
};
</script>
